<template>
  <section>
    <v-container>
      <div style="position:relative;">
        <div class="mt-6">
          <v-row>
            <v-col cols="12" order="2" order-md="1">
              <v-row>
                <v-col sm="4">
                  <v-row class="fill-height">
                    <v-col cols="12" md="6">
                      <bento-box-card>
                        <div class="background-text heated-pool">
                          <div class="goBold-3 drop-font-md">60 years</div>
                          <div class="goBold-2 black--text drop-font-sm">of combined</div>
                          <div class="goBold-3 drop-font-md">experience</div>
                        </div>
                      </bento-box-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <bento-box-card>
                        <div class="goBold-2 black--text drop-font-sm">global recognition</div>
                        <font-awesome-icon :icon="['fas', 'globe']" size="2xl" class="secondary--text my-2 mt-4"/>
                        <div class="goBold-2 black--text drop-font-sm">local respect</div>
                      </bento-box-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="4">
                  <bento-box-card>
                    <div class="goBold-2 black--text">12ft deep onsite</div>
                    <font-awesome-icon :icon="['fas', 'fire']" size="2xl" class="deep-orange--text my-2 mt-4"/>
                    <div class="goBold-4 background-text heated-pool">heated pool</div>
                  </bento-box-card>
                </v-col>
                <v-col sm="4">
                  <bento-box-card>
                    <div class="background-text heated-pool">
                      <div class="goBold-2">extravagant</div>
                      <font-awesome-icon :icon="['fas', 'plane-departure']" size="2x" class="black--text my-2 mt-3"/>
                      <div class="goBold-4">trips</div>
                    </div>
                  </bento-box-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" order="1" order-md="2">
              <v-row>
                <v-col cols="12" sm="4" order="2" order-md="1">
                  <bento-box-card>
                    <v-img class="mx-auto" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/padi-logo.png" max-width="64" contain></v-img>
                    <div class="goBold-2 mt-3 black--text">PADI 5 STAR <br>idc development center</div>
                    <v-rating
                      color="#FFD700"
                      full-icon="fa-solid fa-star"
                      hover
                      length="5"
                      readonly
                      size="20"
                      :value="5"
                    ></v-rating>
                  </bento-box-card>
                </v-col>
                <v-col cols="12" sm="4" order="1" order-md="2">
                  <v-sheet color="primary" class="d-flex justify-center align-center rounded-lg fill-height" style="min-height: 300px;">
                    <div class="white--text text-center">
                      <div class="logo-background-image titleLogo" style="font-size: 64px;">
                        SALT & SEA
                        <div class="subtitleLogo mt-n6" style="font-size: 32px;">
                          SCUBA
                        </div>
                      </div>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col sm="4" order="3" order-md="3">
                  <v-card class="d-flex grey lighten-3">
                    <v-card-text>
                      <div class="text-center background-text heated-pool">
                        <div class="goBold-2">astounding</div>
                        <div class="goBold-3 primary--text drop-font-md">instructors</div>
                        <div class="goBold-2">leading to success</div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-row class="mt-3">
                    <v-col cols="12" md="6">
                      <bento-box-card>
                        <font-awesome-icon :icon="['fas', 'anchor']" size="4x" class="primary--text my-2 mt-4"/>
                        <div class="goBold-2 background-text store-equipment mt-2 ">premier equipment</div>
                      </bento-box-card>
                    </v-col>
                    <v-col cols="12" md="6" class="hidden-xs-only">
                      <bento-box-card>
                        <div class="text-center background-text equipment-tools-panel">
                          <div class="goBold-3 mt-2 drop-font-md">clean & inspected</div>
                          <div class="goBold-2 black--text mt-2">rentals</div>
                        </div>
                      </bento-box-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" order="3" order-md="2">
              <v-row>
                <v-col md="5">
                  <bento-box-card>
                    <div class="goBold-2 black--text">wide range of</div>
                    <font-awesome-icon :icon="['fas', 'arrows-left-right']" size="3x" class="red--text my-1"/>
                    <div class="goBold-4 background-text heated-pool">courses</div>
                  </bento-box-card>
                </v-col>
                <v-col md="3" class="hidden-xs-only">
                  <bento-box-card>
                    <div class="goBold-2 black--text">new and modern</div>
                    <div class="goBold-4 background-text store-equipment">retail space</div>
                  </bento-box-card>
                </v-col>
                <v-col md="4" class="hidden-xs-only">
                  <bento-box-card>
                    <font-awesome-icon :icon="['fas', 'chalkboard-user']" size="2xl" class="purple--text my-4"/>
                    <div class="goBold-2 drop-font-sm black--text">engaging and collaborative</div>
                    <div class="goBold-3 drop-font-md black--text">classroom environment</div>
                  </bento-box-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </div>
      </div>
    </v-container>
    <wave-effect></wave-effect>
    <v-sheet color="primary" class="white--text">
      <v-container>
        <div class="my-10">
          <span class="goBold-3">We are Arizona's most premier scuba shop</span>
          <v-row class="mt-2">
            <v-col cols="12" lg="6">
              <div class="gothic-2 content-font">
                Salt & Sea Scuba began with two passionate divers, bound by their love for the ocean and a vision to redefine diving for all. Whether you're taking your first plunge or you're a seasoned deep-sea explorer, we're here to create an exceptional dive shop experience. Immerse yourself in a whole new way of diving as we unveil the breathtaking wonders beneath the waves, ensuring an adventure that captures the heart of every water enthusiast.
              </div>
            </v-col>
            <v-col class="hidden-sm-and-down">
              <div class="d-flex justify-center align-center">
                <v-img src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/icons/divers_silhouette.png" max-width="300"></v-img>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-sheet>
    <v-row no-gutters>
      <v-col cols="12" sm="6" order="1" order-sm="1">
        <retail-images image-name="indoor-pool-1" height="430">
          <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow"></v-overlay>
        </retail-images>
      </v-col>
      <v-col cols="12" sm="6" order="2" order-sm="2">
        <v-sheet color="primary" class="fill-height white--text">
          <v-container>
            <div class="text-center">
              <div class="goBold-3">Our Indoor Heated Pool</div>
            </div>
            <div class="gothic-2 d-flex justify-center align-center pa-6 content-font" style="min-height: 300px;">
              We're thrilled to offer you an exceptional training environment with our remarkable 12-foot indoor heated pool. As a diver, this pool is a personal haven for learning and improving. It's not just a pool, but a space where beginners can feel at ease and experienced divers can fine-tune their abilities. With its cozy warmth and controlled conditions, Its the perfect place to boost your confidence and master new techniques. Come and join us for a dive into this inviting pool – it's where an unforgettable underwater journeys begin.
            </div>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" order="2" order-sm="1">
        <v-sheet color="primary" class="fill-height white--text">
          <v-container>
            <div class="text-center">
              <div class="goBold-3">Clean and Inspected Rentals</div>
            </div>
            <div class="gothic-2 d-flex justify-center align-center pa-6 content-font" style="min-height: 300px;">
              We take pride in offering you a fleet of meticulously maintained and inspected rental gear. Your safety and comfort are paramount to us, which is why every piece of equipment is thoroughly cleaned and checked before it reaches your hands. We understand that trust is crucial in diving, which is why we guarantee that our rentals are refreshed and renewed every 2 years. Diving with our top-notch gear not only ensures peace of mind but also lets you focus solely on the breathtaking underwater experience.
            </div>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" order="1" order-sm="2">
        <retail-images image-name="guages" height="430">
          <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow"></v-overlay>
        </retail-images>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" order="1" order-sm="1">
        <retail-images image-name="bcd-manikin" height="430">
          <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow"></v-overlay>
        </retail-images>
      </v-col>
      <v-col cols="12" sm="6" order="2" order-sm="2">
        <v-sheet color="primary" class="fill-height white--text">
          <v-container>
            <div class="text-center">
              <div class="goBold-3">Top Tier Instructors</div>
            </div>
            <div class="gothic-2 d-flex justify-center align-center pa-6 content-font" style="min-height: 300px;">
              Our team of instructors is the cornerstone of our dedication to exceptional diving education. With a collective experience of 60 years, our instructors bring a wealth of knowledge and skill to every class. One of our owners, an avid diver, has even explored for renowned institutions like National Geographic, adding a unique layer of insight to our courses. Their hands-on experience translates into invaluable lessons that extend beyond the textbooks, providing you with a comprehensive understanding of the underwater world. With Salt & Sea's instructors, you're not simply learning to dive – you're learning from the absolute best.
            </div>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" order="2" order-sm="1">
        <v-sheet color="primary" class="fill-height white--text">
          <v-container>
            <div class="text-center">
              <div class="goBold-3">Servicing done right</div>
            </div>
            <div class="gothic-2 d-flex justify-center align-center pa-6 content-font" style="min-height: 300px;">
              We believe in going the extra mile to cater to your needs. That's why we offer a convenient service that allows customers to bring in their own products for servicing. Whether it's your cherished dive gear or a crucial accessory, our skilled technicians ensure each item undergoes meticulous care. This personalized approach reflects our commitment to your diving experience. Just like our experienced instructors, your gear is handled with the utmost attention and expertise. With Salt & Sea, you're not just another customer – you're a valued part of our diving community, and your gear receives the exceptional treatment it deserves.
            </div>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" order="1" order-sm="2">
        <retail-images image-name="store-tools" height="440">
          <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow"></v-overlay>
        </retail-images>
      </v-col>
    </v-row>
    <v-sheet color="primary" class="d-flex align-center justify-center" style="height: 400px;">
      <div class="white--text text-center mt-12" style="width: 400px;">
        <div class="goBold-3">thank you and stop by</div>
        <v-divider class="white mt-4"></v-divider>
        <div class="titleLogoSmall">
          SALT & SEA
        </div>
        <div class="subtitleLogoSmall mt-n4">
          SCUBA
        </div>
        <div class="goBold-2 mt-4">(602) 844-5732</div>
        <div class="goBold-1 mt-6">4139 W Bell Rd. Ste 17</div>
        <div class="goBold-1 mt-2">Phoenix, AZ 85053</div>
        <v-divider class="white mt-6"></v-divider>
        <div class="mt-5 gothic-2">"Empowering Arizona adventurers to embrace the depths with a passion."</div>
      </div>
    </v-sheet>
  </section>
</template>

<script>

import BentoBoxCard from '@/components/bentoBoxCard.vue'
import RetailImages from '@/components/retailImages.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'Contact',
  components: { WaveEffect, RetailImages, BentoBoxCard },
  data: () => ({

  }),
  methods: {

  },
  created () {
    this.doSetDocumentTitle('About us', true)
  }
}
</script>

<style scoped lang="scss">
.titleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 128px;
  letter-spacing: -2px;
}

.subtitleLogo {
  font-family: 'Gothic', sans-serif !important;
  font-size: 64px;
  font-weight: 300;
}

.titleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-weight: 800;
  font-size: 46px !important;
  letter-spacing: -2px;
}

.subtitleLogoSmall {
  font-family: 'Gothic', sans-serif !important;
  font-size: 22px !important;
  font-weight: 300;
}

.bottomLineDivider {
  margin-top: -20px;
}

.logo-background-image {
  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Source Sans Pro", sans-serif;
  animation: reveal 3000ms ease-in-out forwards 200ms,
  glow 2500ms linear 2000ms;
}

.background-text {
  background-size: 100%;
  background-position: 50% 50% !important;
  -webkit-background-clip: text !important;
  color: rgba(0,0,0,0.08);
  animation: zoomout-in 5s ease 500ms infinite;
}

.heated-pool {
  background: url(https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/indoor-pool-1-preload.jpg);
}

.equipment-tools-panel {
  background: url(https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/store-tools-preload.jpg);
}

.store-equipment {
  background: url(https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/store-equipment-1-preload.jpg);
}

.content-font {
  font-size: 20px;
  line-height: 26px;
}

// SM
@media screen and (max-width: 959px) {
  .titleLogo {
    font-size: 30px !important;
  }

  .subtitleLogo {
    font-size: 20px !important;
  }

  .drop-font-md {
    font-size: 22px !important;
    line-height: 22px !important;
  }

  .drop-font-sm {
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .content-font {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

// MD
@media screen and (max-width: 1263px) {
  .titleLogo {
    font-size: 42px !important;
  }

  .subtitleLogo {
    font-size: 28px !important;
  }

  .drop-font-md {
    font-size: 26px !important;
    line-height: 26px !important;
  }

  .content-font {
    font-size: 18px;
    line-height: 22px;
  }
}

@keyframes reveal {
  80%{
    letter-spacing: 4px;
  }
  100% {
    background-size: 1000% 1000%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

@keyframes zoomout-in {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 150%;
  }
  100% {
    background-size: 100%;
  }
}
</style>
